.box {
  width: 100%;
}
.text {
  max-width: 229px;
  min-width: 100px;
  font-size: 12px;
  margin-top: 1px;
  color: #ff0000;
  text-align: left;
  margin-left: 0;
  margin-right: auto;
}
