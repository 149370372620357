.root {
  height: 100vh;
  width: 100vw;
  background: rgb(150, 150, 150, 1);
}
.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.logoBox {
  width: 100vw;
  text-align: center;
  padding: 15px;
}

.imageSize {
  width: 150px;
}
.warningSize {
  width: 75px;
}

.infoLabel {
  padding: 15px;
  font-size: 24px;
  color: rgb(191, 21, 40);
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.button {
  margin-top: 15px;
  background: rgb(191, 21, 40);
  color: white;
}
