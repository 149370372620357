.root {
  background: #f5f5f5;
  border-bottom: 1px solid #e8e8e8;
  padding: 18px 24px;
}

.inner {
  width: 100%;
  margin: 0 auto;
}

.name {
  font-size: 16px;
  line-height: 100%;
  padding-left: 18px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
}
