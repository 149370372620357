.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 0 !important;

  div {
    margin-bottom: 0 !important;
  }
}
.errorsContainerLabel {
  color: red;
  width: 100%;
}
.errorsMap {
  color: red;
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 0px;
}
