.root {
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: fixed;
  background: rgb(150, 150, 150, 1);
  z-index: 1003;
}

.logoBox {
  top: -75px;
  position: relative;
  z-index: 1003;
}

.imageSize {
  width: 150px;
}

.infoLabel {
  font-size: 24px;
  top: 50px;
  position: relative;
  z-index: 1003;
  color: rgb(191, 21, 40);
}
